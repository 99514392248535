.article{
    float:left;
    width:100%;

    .video-player-wrapper{
        position: relative;
        // height: 100%;
        // width:100%;
        // float: left;

        .video-player-thumbnail{
            position: absolute;
            z-index: 0;
            width:100%;
            height:100%;

            img{
                width:100%;
                height:100%;
                object-fit: cover; 
            }
        }
        .video-play-button{
            z-index: 9;
            width: 130px;
            height: 130px;
            text-align: center;
            border: thin solid white;
            border-radius: 50%;
            display: grid;
            align-content: center;
            justify-content: center;
            font-size: 110px;
            padding-left: 10px;
            position: absolute;
            color: white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
            cursor: pointer;
            transition: transform 0.1s ease-in-out;
        }
        .video-play-button:hover{
            transform:translate(-50%, -50%) scale(1.07);
        }
    }

    .article-content-wrapper{
        margin-top: 90px;
        width:100%;
        position: relative;
        float: left;
        // padding-bottom: 100px;

        .article-text-column{
            width: 100%;
            float: left;
            // padding-bottom: 50px;
            display: grid;
            justify-items: center;
            margin-top:20px;

            .article-text-column-article-type{
                font-family: "Suisse Medium";
                margin-bottom: 5px;
                width: 50vw;
                max-width: 100%;
                font-size: 14px;
                a{
                    text-decoration: none;
                }
            }

            .article-text-column-title{
                font-family: "Suisse Medium";
                font-size: 41px;
                line-height: 38px;
                margin-bottom: 15px;
                width: 50vw;
                max-width: 100%;
            }
            .article-meta-column{
                font-size:14px;
                line-height: 17px;
                margin-bottom:20px;
                width:50vw;
                max-width:100%;

            }
            .article-bottom-meta-column{
                font-size:13px;
                line-height: 17px;
                // margin-bottom:10px;
                width:100%;
                border-top: thin solid #a5a5a5;
                border-bottom: thin solid #a5a5a5;
                text-align: center;
                padding: 20px 0px;
                display: grid;
                justify-items: center;

                .article-meta-column-buy{
                    a{
                        font-family: 'Suisse Bold';
                        text-decoration:none;
                    }
                }

                .title{
                    font-family: 'Suisse Bold';
                }
                .article-meta-column-tags{
                    .meta-tags-list{
                        width:250px;
                        max-width: 100%;
                    }
                    span{
                        color: #a5a5a5;
                        text-decoration: underline;
                        cursor: pointer;
                        margin: 0px 5px;
                        display: inline-block;
                    }
                }

                .article-meta-column-share{
                    display: grid;
                    justify-content: center; 
                    .article-meta-column-share-buttons{
                        font-size: 20px;
                        display: grid;
                        color: #000;
                        grid-template-columns: 1fr 1fr 1fr;
                        column-gap: 10px;
                    }
                    .meta-mail-icon{
                        font-size: 25px;
                        transform: translateY(-1px);
                    }
                }

                .article-meta-column-tags, .article-meta-column-share{
                    margin-bottom:20px;
                }
                .article-meta-column-newsletter-wrapper{
                    opacity:0;
                    height:0;
                    transition: all 0.2s ease-in-out
                }
                .display{
                    opacity:1;
                    height:130px; 
                }
                
            }
            .article-text-column-intro{
                font-size: 17px;
                line-height: 23px;
                width:50vw;
                max-width:100%;
                font-family: 'Suisse Serif';
            }
            .article-text-column-content{
                // font-size: 17px;
                // line-height: 22px;
                width:100%;
                // max-width:100%;
                margin-bottom: 20px;
                display: grid;

                ul, ol{
                    width:50vw;
                    justify-self: center;
                    
                    li{
                        list-style: disc;
                    }
                }

                p{
                    margin:10px 0px;
                    width: 50vw;
                    justify-self: center;
                    float:left;
                }

                strong{
                    font-family: 'Suisse Serif';
                }

                .footnote-indicator{
                    font-size: 13px;
                    transform: translate(-2px, -5px);
                    display: inline-block;
                }

                .content-quote-wrapper{
                    width: 50vw;
                    justify-self: center;
                    float:left;
                    font-family: 'Suisse Serif';
                    font-size: 22px;
                    line-height: 29px;
                    padding: 10px 0px;

                    p{
                        margin:auto;
                        width:100%;
                        display: inline;
                        float: none;
                    }
                    .content-quote-person{
                        text-transform: uppercase;
                        font-size: 13px;
                        font-family: 'Suisse Medium';
                        padding-left: 5px;
                        white-space: normal;
                        // white-space: nowrap;
                    }
                }

                .content-row-wrapper{
                    width:100%;
                    float:left;
                    display: grid;

                    .content-row-five{
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        column-gap: 40px;
                        width: 100%;
                    }
                    
                    .content-row-four{
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        column-gap: 40px;
                        width: 100%;
                    }
                    .content-row-three{
                        justify-self: center;
                        grid-template-columns: 1fr 1fr 1fr;
                        column-gap: 40px;
                        width:75%;
                    }
                    .content-row-two{
                        justify-self: center;
                        grid-template-columns: 1fr 1fr;
                        column-gap: 40px;
                        width:50%;
                    }
                    .content-row-one{
                        justify-self: center;
                        grid-template-columns: 1fr;
                        width:50%;
                        padding: 20px 0px !important;
                    }
                    .content-row{
                        // width: 100%;
                        float:left;
                        display: grid;
                        padding: 20px 40px;

                        .content-row-image-wrapper{
                            width: 100%;
                            float:left;

                            img{
                                max-width:100%;
                            }
                            p{
                                margin:auto;
                                width:100%;
                            }
                            .content-row-image-caption{
                                font-size: 13px;
                                line-height: 17px;
                                color:gray;
                                width: 100%;
                                word-wrap: break-word;
                            }
                        }
                        .content-row-quote-wrapper{
                            width: 100%;
                            float:left;
                            font-family: 'Suisse Serif';
                            font-size: 22px;
                            line-height: 29px;

                            p{
                                margin:auto;
                                width:100%;
                                display: inline;
                                float: none;
                            }
                            .content-row-quote-person{
                                text-transform: uppercase;
                                font-size: 13px;
                                font-family: 'Suisse Medium';
                                padding-left: 5px;
                                line-height: 17px;
                                // white-space: nowrap;
                            }
                        }
                    }

                    .content-row-embed-wrapper{
                        width:100%;

                        .content-row-embed-content{
                            width:100%;

                            iframe{
                                max-width:100%;
                            }
                        }
                    }
                    // .content-row-four{
                    //     .content-row-image-wrapper,.content-row-quote-wrapper{
                    //         width:25%;
                    //     }
                    // }
                    // .content-row-three{
                    //     .content-row-image-wrapper,.content-row-quote-wrapper{
                    //         width:33.33%;
                    //     }
                    // }
                    // .content-row-two{
                    //     .content-row-image-wrapper,.content-row-quote-wrapper{
                    //         width:50%;
                    //     }
                    // }
                    // .content-row-one{
                    //     .content-row-image-wrapper,.content-row-quote-wrapper{
                    //         width:100%;
                    //     }
                    // }

                }
            }

            .article-text-column-footnotes{
                font-size:13px;
                line-height: 17px;
                margin-top:20px;
                margin-bottom: 20px;
                width: 50vw;
                max-width:100%;

                .footnote-label{
                    font-family: 'Suisse Bold';
                    // font-weight:600;
                }
                
                .footnote-wrapper{
                    display: grid;
                    grid-template-columns: 20px auto;
                    .footnote-number{
                        font-family: 'Suisse Bold';
                        width: 20px;
                        display: inline-block;
                        // font-weight: 600;
                    }
                    p{
                        display:inline;
                    }
                }
            }
            .ad-image-wrapper{
                .advertise-label{font-size:10px;color:gray}
                width:50vw;
                margin:20px 0px;
                img{
                    max-width: 100%;
                }
            }
            .article-text-column-author-bio{
                font-size: 13px;
                margin-top: 20px;
                margin-bottom: 20px;
                line-height: 17px;
                width: 50vw;
                max-width:100%;
            }
        }

        .article-single-video{
            width:100%;
            // height: 70vh;
            height: auto;
            float: left;
            padding-bottom: 20px;
            position:relative;

        }

        .article-single-highlight{
            width:100%;
            height: auto;
            float: left;
            // padding:20px 0px;
            // margin-top:15px;

            .article-single-image{
                width:50vw;
                margin-left:25vw;
                float:left;
                // max-height:70vh;
                // display: grid;
                // justify-self: center;
                img{
                    width:100%;
                }
            }
            .article-single-image-caption{
                font-size: 13px;
                line-height: 17px;
                color:gray;
                float:left;
                width:50vw;
                margin-left:25vw;
            }
            .article-single-video{
                width:100%;
                height:auto;

                .video-play-button{
                    z-index: 9;
                    width: 130px;
                    height: 130px;
                    text-align: center;
                    border: thin solid white;
                    border-radius: 50%;
                    display: grid;
                    align-content: center;
                    justify-content: center;
                    font-size: 110px;
                    padding-left: 10px;
                    position: absolute;
                    color: white;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(1);
                    cursor: pointer;
                    transition: transform 0.1s ease-in-out;
                }
                .video-play-button:hover{
                    transform:translate(-50%, -50%) scale(1.07);
                }
            }
        }

        .article-image-slider{
            width:100%;
            // height: 70vh;
            height: auto;
            float: left;
            padding:20px 0px;
            // margin: 15px 0px 100px 0px;
            // margin-top:15px;

            .swiper-ref-prev{
                z-index: 999;
                position: absolute;
                height: 100%;
                top: 0;
                width: 20vw;
                min-width:200px;
                left: 0;
                cursor: none;
            }

            .swiper-ref-next{
                position: absolute;
                z-index: 999;
                height: 100%;
                top: 0;
                width: 20vw;
                min-width:200px;
                right: 0;
                cursor: none;
            }

            .swiper-slide{
                padding: 0px 10px;
            }
            
            .article-image-slider-wrapper{
                // padding:0px 10px;
                width: auto;
                height: auto;
                float: left;

                img{
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .article-image-slider-caption{
                // margin-top: 10px;
                color: #a5a5a5;
                font-size: 13px;
                line-height: 17px;
                float: left;
                width: 100%;
                word-wrap: break-word;
            }
            .swiper-video{
                        
                // .video-play-button{
                //     z-index: 9;
                //     width: 130px;
                //     height: 140px;
                //     text-align: center;
                //     border: thin solid white;
                //     border-radius: 50%;
                //     display: grid;
                //     align-content: center;
                //     justify-content: center;
                //     font-size: 110px;
                //     padding-left: 10px;
                //     position: absolute;
                //     color: white;
                //     top: 50%;
                //     left: 50%;
                //     transform: translate(-50%, -50%) scale(1);
                //     cursor: pointer;
                //     transition: transform 0.1s ease-in-out;
                // }
                // .video-play-button:hover{
                //     transform:translate(-50%, -50%) scale(1.07);
                // }
            }
        }

        .article-image-column{
            width: 40%;
            float: left;
            padding-right: 20px;

            .article-image-column-image-wrapper{
                margin-bottom:20px;

                .article-image-column-image{
                    img{max-width: 100%;}
                }

                .article-image-column-caption{
                    margin-top: 10px;
                    color: #a5a5a5;
                    font-size: 13px;
                    line-height: 17px;
                }
            }
        }
        .article-back-top{
            position:absolute;
            bottom:10px;
            left: 20px;
            color: #a5a5a5;
            font-size: 13px;
            line-height: 17px;
            text-transform: uppercase;
        }
    }
}

.article-rr{
    // background-color: #cdcdcd;
}

@media screen and (max-width:1100px){

        .video-play-button{
            pointer-events: none !important;
            height:130px !important;
            width:130px !important;
        }

        .swiper-slide{
            .video-play-button{
                height:140px !important;
            }
        }

    .article-single-image {
        margin-left: 20px !important;
        width: calc(100% - 40px) !important;
    }
    .article-single-image-caption{
        margin-left: 20px !important;
        width: calc(100% - 40px) !important;
    }

    .article-text-column{
        padding:0px 20px;

        .article-text-column-author-bio,
        .ad-image-wrapper, 
        .article-text-column-article-type, 
        .article-text-column-title,
        .article-meta-column
        {
            width:100% !important;
        }
        p{
            width:100% !important  
        }
        // .video-play-button{
        //     height:130px !important;
        //     width:130px !important;
        // }

        .content-row-one{
            width: 100% !important;
        }
        .content-quote-wrapper{
            width:100% !important;
        }

        .content-row{
            display: block !important;
            padding: 20px 0px !important;
            width: 100% !important;


            .content-row-image-wrapper{
                margin-bottom: 40px;
            }
            .content-row-quote-wrapper{
                margin-bottom: 40px;

                .content-quote-person{
                    white-space: normal !important;
                    line-height: 17px;
                    // white-space: break-spaces;
                }
            }
        }
    }

    .article-image-slider{
        margin-top: 0px !important;
        .swiper-slide {
            width: calc(100vw - 60px) !important;
            padding: 0px 0px 0px 20px !important;
        }
        .swiper-slide:first-child {
            padding-left: 10px !important;
        }
    }
}