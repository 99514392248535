.cursor-next, .cursor-previous{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    pointer-events: none;
    color:white;
    font-size: 22px;
    float:left;
    height:29px;
    line-height: 29px;
    mix-blend-mode: difference;
}

.cursor-next{
    width:100px;
}
.cursor-previous{
    width:150px;
}

.label{
    float:left;
}

.arrow-wrapper{
    float:left;
    display: grid;
    align-items: center;
    font-size:25px;
    height:22px;
    line-height: 22px;
}
