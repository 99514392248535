a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    // color:#0A4199;
    cursor: pointer;
}

.menu-static {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;
    width: 100%;
    z-index: 9;
    background: #fff;
    padding-top: 11px;
    padding-bottom: 8px;
    height: 51px;
    position: sticky;
    top: 0;
    border-bottom: 1px solid gray;

    .menu-section-left {
        display: flex;
        align-items: center;
    }

    .menu-section-right {
        display: flex;
        justify-content: end;
        align-items: center;
        padding-right: 20px;
        gap: 10px;

        .menu-issue-number {
            width: 20px;
            display: inline-block;
        }

        .menu-section-right-buy {
            float: left;
            line-height: 20px;
            cursor: pointer;
        }

        .menu-section-right-search-button {
            float: left;
            font-size: 20px;
            line-height: 13px;
            margin-left: 7px;
            margin-top: -1px;
            cursor: pointer;
        }

        .menu-section-right-menu-button {
            float: left;
            font-size: 19px;
            line-height: 12px;
            margin-left: 30px;
            // margin-left:58px;
            margin-top: -1px;
            cursor: pointer;
        }
    }

    .menu-search-wrapper {
        width: 70%;
        float: left;
        position: absolute;
        height: 20px;
        margin-left: 15%;

        .menu-search-field {
            font-family: 'Suisse Serif';
            color: gray;
            width: 50%;
            min-width: 300px;
            border-bottom: thin solid;
            text-align: center;
            float: left;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
            font-size: 20px;
            line-height: 21px;
            padding-top: 11px;

            input {
                width: 100%;
                border: none;
                text-align: center;
                font-family: 'Suisse Serif';
                font-size: 20px;
            }

            input:focus {
                outline: none;
            }

            .menu-search-button {
                position: absolute;
                right: 0px;
                top: 11px;
            }
        }
    }

    .menu-section-center {
        display: grid;
        justify-content: center;
        grid-template-columns: auto auto auto;
        width: 50vw;
        margin: 0 auto;

        div {
            justify-self: center;
            text-align: center;

            margin: 0px 10px;
        }

        div:first-child {
            margin-left: 0px;
        }
    }
}

.menu-section-expanded {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;
    padding: 14px 20px;
    width: 100%;
    transform: translatey(0px);
    transition: height ease 0.2s, opacity 0.1s ease, transform ease 0.2s;
    background-color: white;
    border-bottom: thin solid grey;
    position: relative;
    z-index: 2;

    .menu-section-about {
        width: 25%;
        float: left;
        padding-left: 20px;
    }

    .menu-section-center {
        display: grid;
        justify-content: center;
        grid-template-columns: auto auto auto;
        width: 50vw;
        float: left;

        div {
            justify-self: center;
            text-align: center;

            margin: 0px 10px;
        }

        div:first-child {
            margin-left: 0px;
        }
    }

    .menu-slogan {
        grid-column-start: 2;
        height: 20px;
        margin: 0 auto;
        text-align: center;
        font-size: 18px;
        pointer-events: none;
        opacity: 0;
        font-family: 'Suisse Serif';
    }

    .slogan-visible {
        animation: slogan-fadein 1s forwards;
        animation-delay: 0.5s;
    }

    @keyframes slogan-fadein {
        0% {
            opacity: 0
        }

        100% {
            opacity: 1
        }
    }

    .menu-section-newsletter-wrapper {
        display: flex;
        justify-content: end;
        align-items: center;
        right: 20px;
        top: 14px;
        z-index: 10;
        gap: 10px;

        .menu-section-newsletter {
            .menu-section-title {
                cursor: pointer;
            }
        }

        .menu-section-social-buttons {
            float: left;
            display: grid;
            font-size: 16px;
            /* line-height: 17px; */
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 10px;
            /* align-items: center; */
            padding-top: 3px;
            padding-left: 10px;
        }
    }
}

.menu-section-expanded-display {
    pointer-events: all;
    opacity: 1;
    height: 52px;
    transform: translatey(51px);
}

.menu-section-title {
    font-size: 20px;
    line-height: 25px;
    font-family: 'Suisse Medium';
}

.menu-section-item {
    font-size: 13px;
    color: gray;
    line-height: 17px;
}

.menu-section-logo {
    padding-left: 20px;
    margin-right: 20px;
    height: 25px;
    cursor: pointer;

    svg {
        height: 100%;
    }
}

//   }


.menu-section-newsletter-expanded {
    position: absolute;
    height: 160px;
    margin-top: 0px;
    margin-bottom: 7px;
    padding-top: 11px;
    width: 100%;
    opacity: 0;
    transform: translatey(-160px);
    transition: opacity 0.2s ease, transform ease 0.2s;
    pointer-events: none;
    background-color: white;
    z-index: 1;
    border-bottom: thin solid grey;
    display: grid;
    z-index: 1;

    .menu-section-newsletter-content {

        display: grid;
        position: relative;
        justify-self: center;
        width: 410px;
        max-width: 100%;

        input:focus {
            outline: none;
        }


        .menu-section-email-form {
            border: none;
            width: 100%;
            border-bottom: thin solid;
            line-height: 15px;
            padding-top: 2px;
        }

        .menu-section-email-arrow {
            position: absolute;
            top: 5px;
            right: 5px;
        }

        .menu-section-email-opt-wrapper {
            display: grid;
            grid-template-columns: 20px auto;
            padding-top: 4px;
            font-size: 13px;
            align-content: center;
            margin-top: 5px;
            position: relative;

            .menu-section-email-opt-tick-wrapper {
                width: 15px;
                height: 15px;
                position: relative;

                [type=checkbox] {
                    width: 15px;
                    height: 15px;
                    vertical-align: middle;
                    -webkit-appearance: none;
                    background: none;
                    border: thin solid black;
                    outline: 0;
                    flex-grow: 0;
                    border-radius: 50%;
                    background-color: #FFFFFF;
                    transition: background 300ms;
                    cursor: pointer;
                    margin: 0;
                }

                [type=checkbox]:hover {

                    border: thin solid gray;
                }


                /* Pseudo element for check styling */

                [type=checkbox]::before {
                    content: "";
                    color: transparent;
                    display: block;
                    width: inherit;
                    height: inherit;
                    border-radius: inherit;
                    border: 0;
                    background-color: transparent;
                    background-size: contain;
                    // box-shadow: inset 0 0 0 1px #CCD3D8;
                }


                /* Checked */

                [type=checkbox]:checked {
                    background-color: currentColor
                }

                [type=checkbox]:checked::before {
                    box-shadow: none;
                }


                /* Show the checkmark when checked */
            }

            .menu-section-email-opt-tick-wrapper input:checked~.menu-section-email-opt-tick:after {
                display: block;
            }

            .menu-section-email-opt-label {
                align-self: center;
                padding-top: 2px;
            }
        }

        .menu-section-email-submit {
            padding-top: 8px;
            font-family: "Suisse Medium";
            cursor: pointer;
        }

        .newsletter-submit-display {
            height: auto;
            opacity: 1;
            overflow: visible;
        }
    }
}

.menu-section-newsletter-expanded-display {
    pointer-events: all;
    opacity: 1;
    height: 160px;
    transform: translatey(0);
    padding-bottom: 10px;
}

.damn-logo {
    fill: black;
}
