.contact-content-wrapper{
    width:50vw;
    margin-left:25vw;
    margin-top:60px;
    margin-bottom: 100px;
    float: left;

    p:empty{
        height: 23px;
    }

    .contact-title{
        font-family: "Suisse Medium";
        font-size: 37px;
        margin-bottom:20px;
    }

    .contact-leftcol{
        width:50%;
        float:left;
        padding-right: 20px;
    }
    .contact-rightcol{
        width:50%;
        float:left;
    }
}

@media screen and (max-width:1100px){

    .contact-content-wrapper{
        padding:0px 20px;
        width:100% !important;
        margin-left:0px !important;

        .contact-leftcol{
            width:100% !important;
            margin-bottom:23px;
        }
        .contact-rightcol{
            width:100% !important;
        }
    }
}