.fullscreen-image-wrapper{
    // display: none;
    position: fixed;
    z-index: 999;
    width:100%;
    height:100%;
    top:0;
    left:0;

    @keyframes fsFadeIn {
        0%{transform:scale(0.95);opacity: 0;}
        100%{transform:scale(1);opacity: 1;}
    }

    .fullscreen-content{
        position: relative;
        height:100%;
        width: 100%;
        z-index: 2;
        display: grid;
        align-content: center;
        justify-content: center;
        transform:scale(0.8);
        opacity: 0;

        .fullscreen-image{
            float:left;
            max-width: calc(100vw - 100px);
            max-height: calc(100vh - 130px);
            
            img{
                max-height: 100%;
                max-width:100%;
                width: auto;
                height: auto;
            }
        }
        .fullscreen-caption{
            font-size: 13px;
            line-height: 17px;
            color: white;
            padding-top: 10px;
            float: left;
            width: auto;
            height: auto;
        }
    }

    .display{
        animation-name: fsFadeIn;
        animation-duration: 0.2s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }

    @keyframes bgFadeIn {
        0%{opacity: 0}
        100%{opacity: 1}
    }

    .fullscreen-image-background{
        position: absolute;
        top:0;
        z-index: 1;
        width:100%;
        height: 100%;
        background-color: rgba(0,0,0,0.8);
        animation-name: bgFadeIn;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }
}


@media screen and (max-width:1100px){

    .fullscreen-image{
        max-height: calc(100vh - 100px) !important;
        display: grid;
        justify-self: center;

    }

    .fullscreen-caption{
        width: 100% !important;
        padding: 20px !important;
    }

}