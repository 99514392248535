.mc__form-container{
    display: grid;
    align-items: center;
}

.mc__alert{
    text-align: center;
}

.menu-section-email-opt-wrapper{
    display: grid;
    grid-template-columns: 20px auto;
    padding-top: 4px;
    font-size: 13px;
    align-content: center;
    margin-top:5px;
    position:relative;
    text-align: left;
    
    .menu-section-email-opt-tick-wrapper{
        width: 15px;
        height: 15px;
        position: relative;

        [type=checkbox] {
            width: 15px;
            height: 15px;
            vertical-align: middle;
            -webkit-appearance: none;
            background: none;
            border: thin solid black;
            outline: 0;
            flex-grow: 0;
            border-radius: 50%;
            background-color: #FFFFFF;
            transition: background 300ms;
            cursor: pointer;
            margin: 0;
        }

        [type=checkbox]:hover {
            
            border: thin solid gray;
        }
        
        
        /* Pseudo element for check styling */
        
        [type=checkbox]::before {
            content: "";
            color: transparent;
            display: block;
            width: inherit;
            height: inherit;
            border-radius: inherit;
            border: 0;
            background-color: transparent;
            background-size: contain;
            // box-shadow: inset 0 0 0 1px #CCD3D8;
        }
        
        
        /* Checked */
        
        [type=checkbox]:checked {
            background-color: currentColor
        }
        
        [type=checkbox]:checked::before {
            box-shadow: none;
        }

        
        /* Show the checkmark when checked */
    }
    .menu-section-email-opt-tick-wrapper input:checked ~ .menu-section-email-opt-tick:after {
        display: block;
    }

    .menu-section-email-opt-label{
        align-self: center;
        padding-top: 2px;
    }
}

@media screen and (max-width:1100px){

    .menu-section-email-opt-wrapper{
        font-size: 16px;
    }
}