.issues-wrapper{
    width:100%;
    float:left;

.issues-grid-wrapper{
    float:left;
    width:100%;
    margin-top:60px;
    padding:0px 10px;

    .single-issue-wrapper{
        float: left;
        width:20%;
        padding: 0px 10px;
        margin-bottom: 50px;
        a{
            text-decoration: none;
        }

        .single-issue-image-wrapper{
            width: 100%;
            height: 25vw;
            img{
                width:100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .single-issue-title-wrapper{
            width:100%;
            padding-top:10px;
            line-height: 20px;
            text-align: center;
            font-family: 'Suisse Medium';
            height: 50px;
        }
    }
}

}

@media screen and (max-width:1100px){
    .issues-grid-wrapper{
        .single-issue-wrapper{
            width:50% !important;
            
            .single-issue-image-wrapper{
                height: calc(65vw - 30px) !important;
            }
        }
    }
}