@keyframes fadeIn {
    0%{opacity: 0}
    100%{opacity:1}
}

.popup-wrapper{
    position: fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width: 750px;
    max-width: calc(100vw - 40px);
    height: 250px;
    background-color: white;
    z-index: 999;
    padding:20px;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay: 1s;

    box-shadow:
    0px 0px 1.3px rgba(0, 0, 0, 0.019),
    0px 0px 3.3px rgba(0, 0, 0, 0.027),
    0px 0px 6.7px rgba(0, 0, 0, 0.033),
    0px 0px 13.9px rgba(0, 0, 0, 0.041),
    0px 0px 38px rgba(0, 0, 0, 0.06)
    ;


    .popup-image{
        width: 245px;
        height: 100%;
        float:left;

        img{
            max-width: 100%;
        }
    }

    .popup-content{
        width: calc(100% - 250px);
        float: left;
        padding-left: 30px;
        position: relative;
        height: 100%;

        .popup-close{
            position: absolute;
            top: 0px;
            right: 0px;
            cursor:pointer;
            font-size: 19px;
            line-height: 12px;

        }

        .popup-text{
            display:none;
            // color:#0a4199;

            p{
                padding-right: 50px;
                font-family: 'Suisse Serif';
                font-size: 20px;
                line-height: 26px;
            }
        }

        .popup-newsletter-expanded{
            display: none;

            .newsletter-title{
                font-family: 'Suisse Medium';
                margin-bottom:8px;
            }

            .popup-email-form{
                border:none;
                width:100%;
                border-bottom:thin solid;
                line-height: 15px;
                padding-top: 10px;
            }
            .popup-email-arrow{
                position: absolute;
                top: 5px;
                right: 5px;
            }
            .popup-email-submit-wrapper{
                width: 100%;
                position: absolute;
                bottom: 0;
                text-align: center;
                display: grid;
                justify-content: center;
                padding-right: 20px;

                .popup-email-opt-wrapper{
                    display: grid;
                    grid-template-columns: 20px auto;
                    padding-top: 4px;
                    font-size: 13px;
                    align-content: center;
                    margin-top:5px;
                    position:relative;
                    
                    .popup-email-opt-tick-wrapper{
                        width: 15px;
                        height: 15px;
                        position: relative;

                        [type=checkbox] {
                            width: 15px;
                            height: 15px;
                            vertical-align: middle;
                            -webkit-appearance: none;
                            background: none;
                            border: thin solid black;
                            outline: 0;
                            flex-grow: 0;
                            border-radius: 50%;
                            background-color: #FFFFFF;
                            transition: background 300ms;
                            cursor: pointer;
                            margin: 0;
                        }

                        [type=checkbox]:hover {
                            background-color: gray;
                            border: thin solid gray;
                        }
                        
                        
                        /* Pseudo element for check styling */
                        
                        [type=checkbox]::before {
                            content: "";
                            color: transparent;
                            display: block;
                            width: inherit;
                            height: inherit;
                            border-radius: inherit;
                            border: 0;
                            background-color: transparent;
                            background-size: contain;
                            // box-shadow: inset 0 0 0 1px #CCD3D8;
                        }
                        
                        
                        /* Checked */
                        
                        [type=checkbox]:checked {
                            background-color: currentcolor;
                        }
                        
                        [type=checkbox]:checked::before {
                            box-shadow: none;
                        }

                        
                        /* Show the checkmark when checked */
                    }
                    .popup-email-opt-tick-wrapper input:checked ~ .popup-email-opt-tick:after {
                        display: block;
                    }

                    .popup-email-opt-label{
                        align-self: center;
                        padding-top: 2px;
                    }
                }
            }
            .popup-email-submit{
                padding-top:3px;
                font-family: "Suisse Medium";
                cursor: pointer;
            }
        }

        .popup-bottom{
            // font-size: 15px;
            width:100%;
            text-align: center;
            position: absolute;
            // bottom:0;
            bottom: -7px;
            padding-right: 20px;
            // font-size: 13px;
            font-family: 'Suisse Bold';
            // line-height: 17px;
            // height: 13px; (if only one field)
            // height: 38px; 

            a{
                color:inherit;
                text-decoration: none;
            }
            div{
                cursor:pointer
            }
            div:first-child{
                // margin-bottom: 5px;
            }
        }

        .display{
            display: block !important;
        }
    }
}

@media screen and (max-width:1100px){

    .popup-wrapper{
        height: auto !important;
        max-width: calc(100vw - 40px) !important;

        .popup-image{
            width: 100% !important;
            height: auto !important;

            img{
                width: 100px !important;
                height: auto !important;
            }
        }

        .popup-content{
            width: 100% !important;
            padding-left: 0px !important;
            padding-top: 20px;

            p{
                padding-right: 0px !important;
            }

            .popup-close{
                top: -95px !important;
            }
        }

        .popup-bottom{
            position: relative !important;
            padding-left: 0px !important;
            padding-top: 20px !important;
            height: auto !important;
        }

        .popup-email-submit-wrapper{
            position: relative !important;
        }
    }

}