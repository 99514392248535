.footer{
    float: left;
    width: 100%;
    padding-right: 20px;
    padding-top:10px;
    // background-color: rgb(242,242,242);

        .footer-article{
            height: 100%;
            width: 20%;
            float: left;
            padding:10px;
            cursor: pointer;

            a{
                text-decoration: none;
            }

            .footer-article-image{
                width: 100%;
                overflow: hidden;
                height: calc(20vw / 1.5);

                img{
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                    transform:scale(1);
                    transition:transform 0.15s ease-in-out
                }
            }
            
            .footer-article-label{
                width: 100%;
            }

            .footer-article-label:hover{
                color:#0a4199;
            }

            .height-ratio-100{
                height: calc(20vw / 0.75);
            }

            .height-ratio-075{
                height: calc(20vw / 1);
            }

            .height-ratio-050{
                height: calc(20vw / 1.25);
            }

            .height-ratio-025{
                height: calc(20vw / 1.50);
            }

            .footer-article-title{
                font-size: 29px;
                line-height: 31px;
                font-family: "Suisse Medium";
                padding-top: 10px;
                
            }
            .footer-article-byline{
                text-transform: uppercase;
                font-size: 12px;
                line-height: 17px;
                font-family: "Suisse Regular";
                padding-top: 5px;
            }
        }
        .footer-article:hover{
            .footer-article-image{
                img{
                    mix-blend-mode: screen;
                    -webkit-filter: grayscale(100%) contrast(200%);
                    filter: grayscale(100%) contrast(200%);
                    opacity: 1;
                }
            }
        }
        .footer-contact{
            float:left;
            width:100%;
            padding:50px 0px 10px 20px;
            font-size:12px;
            text-align: center;
        }
}
.footer-article:first-child{
    margin-left:20%;
}

.threeCol{ 
    padding-right: 0px !important;
        .footer-article{
        width: 50%;
        margin-left:0 !important;
    }
}
.oneCol{
    // padding-right: 0px !important;
    .footer-article{
        width: 100%;
        margin-left:0 !important;
        padding:0px 20px !important;
    }
}

.fiveCol{
    .height-ratio-100{
        height: calc(20vw / 0.75);
    }

    .height-ratio-075{
        height: calc(20vw / 1);
    }

    .height-ratio-050{
        height: calc(20vw / 1.25);
    }

    .height-ratio-025{
        height: calc(20vw / 1.50);
    }
    .double-width{
        width: 40% !important;
    }
    .single-width{
        width: 20% !important;
    }
}
.fourCol{
    .height-ratio-100{
        height: calc(25vw / 0.75) !important;
    }

    .height-ratio-075{
        height: calc(25vw / 1) !important;
    }

    .height-ratio-050{
        height: calc(25vw / 1.25) !important;
    }

    .height-ratio-025{
        height: calc(25vw / 1.50) !important;
    }
    .double-width{
        width: 50% !important;
    }
    .single-width{
        width: 25% !important;
    }
}
.threeCol{

    .height-ratio-100{
        height: calc(33vw / 0.75) !important;
    }

    .height-ratio-075{
        height: calc(33vw / 1) !important;
    }

    .height-ratio-050{
        height: calc(33vw / 1.25) !important;
    }

    .height-ratio-025{
        height: calc(33vw / 1.50) !important;
    }

    .double-width{
        width: 66.66% !important;
    }
    .single-width{
        width: 33.33% !important;
    }
}
.oneCol{
    .height-ratio-100{
        height: calc(100vw / 0.75) !important;
    }

    .height-ratio-075{
        height: calc(100vw / 1) !important;
    }

    .height-ratio-050{
        height: calc(100vw / 1.25) !important;
    }

    .height-ratio-025{
        height: calc(100vw / 1.50) !important;
    }
    .double-width{
        width: 100% !important;
    }
    .single-width{
        width: 100% !important;
    }
}

@media screen and (max-width:540px){

    .footer{
        padding-right:0px !important;

        .swiper-slide{
            width: calc(100vw - 60px) !important;
            padding-right: 20px;

            .footer-article-image {
                width: 100%;
            }

        }
    
    }
    .footer-contact{
    padding:50px 20px 20px 20px !important;
    line-height: 18px;

        .footer-links{
            width:100%;
            display: block;
        }
    }

}