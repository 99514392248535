.about-content-wrapper{
    margin-top: 60px;
    width:50vw;
    margin-left:25vw;
    position: relative;
    float: left;
    padding-bottom: 100px;
    
    .about-title{
        font-family: "Suisse Medium";
        font-size: 37px;
        margin-bottom:20px;
    }

    .about-image{
        width: 100%;
        float: left;
        margin-bottom:0px;
        
        img{
            max-width: 100%;
        }
    }

    .about-text{
        width: 100%;
        float: left;
        font-family: 'Suisse Serif';

        .about-bottom-text{
            margin-top:30px;
            font-family: 'Suisse Medium';
            text-align: center;

            a{
                text-decoration: none;
                font-family: 'Suisse Bold';
            }

            p{
                margin:0px;
            }
            p:empty{
                margin:15px 0px;
            }
        }

        p{
            margin:15px 0px;
        }
    }
}

@media screen and (max-width:1100px){

    .about-content-wrapper{
        padding:0px 20px;
        width:100% !important;
        margin-left:0px !important;
    }
}