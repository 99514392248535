.page404-content-wrapper{
    width:100%;
    height:calc(100vh - 100px);
    display: grid;
    align-content:center;
    text-align: center;
    font-size:30px;
    line-height: 40px;
    font-family: 'Suisse Serif';
}


@media screen and (max-width:1100px){

}