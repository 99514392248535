.collection-page{
    a{
        text-decoration: none;
    }

    .collection-page-content-wrapper{
        opacity: 1;
        width: 100%;
        min-height: calc(100vh - 13.333333vw + 39px);
    }

    .collection-page-article-section{
        width: 100%;
        // margin-top: 100px;

        .no-results{
            width: 100%;
            text-align: center;
            font-size: 40px;
            margin: 200px 0px;
            font-family: 'Suisse Serif';
            color: gray;
        }

        .collection-page-article-row{
            display: grid;
            width: 100%;
            margin-bottom: 20px;
            grid-template-columns: repeat(5, 1fr);
            height: auto;
            padding: 10px;

            @media screen and (max-width: 1200px){
                grid-template-columns: repeat(4, 1fr);
            }

            @media screen and (max-width: 700px){
                grid-template-columns: repeat(3, 1fr);
            }

            @media screen and (max-width: 540px){
                grid-template-columns: repeat(1, 1fr);
            }

            .collection-page-article{
                height: 100%;
                min-height: calc(((20vw)) + 220px);
                padding:10px;
                cursor: pointer;

                @media screen and (max-width: 1200px){
                    min-height: calc(((25vw)) + 230px);
                }

                @media screen and (max-width: 700px){
                    min-height: calc(((33vw)) + 260px);
                }

                @media screen and (max-width: 540px){
                    min-height: calc(((100vw)) + 260px);
                }

                .height-ratio-100{
                    height: calc((20vw - 30px) / 0.75);

                    @media screen and (max-width: 1200px){
                        height: calc((25vw - 30px) / 0.75);
                    }

                    @media screen and (max-width: 700px){
                        height: calc((33vw - 30px) / 0.75);
                    }

                    @media screen and (max-width: 540px){
                        height: calc((100vw - 30px) / 0.75);
                    }
                }

                .height-ratio-075{
                    height: calc(20vw / 1);

                    @media screen and (max-width: 1200px){
                        height: calc(25vw / 1);
                    }

                    @media screen and (max-width: 700px){
                        height: calc(33vw / 1);
                    }

                    @media screen and (max-width: 540px){
                        height: calc(100vw / 1);
                    }
                }

                .height-ratio-050{
                    height: calc(20vw / 1.25);

                    @media screen and (max-width: 1200px){
                        height: calc(25vw / 1.25);
                    }

                    @media screen and (max-width: 700px){
                        height: calc(33vw / 1.25);
                    }

                    @media screen and (max-width: 540px){
                        height: calc(100vw / 1.25);
                    }
                }

                .height-ratio-025{
                    height: calc(20vw / 1.50);

                    @media screen and (max-width: 1200px){
                        height: calc(25vw / 1.50);
                    }

                    @media screen and (max-width: 700px){
                        height: calc(33vw / 1.50);
                    }

                    @media screen and (max-width: 540px){
                        height: calc(100vw / 1.50);
                    }
                }

                .double-width {
                    grid-column: span 2;

                    @media screen and (max-width: 540px){
                        grid-column: span 1;
                    }
                }

                .collection-page-article-image{
                    width: 100%;
                    overflow: hidden;

                    .video-thumbnail-wrapper{
                        width: 100%;
                        height: 100%;
                        position: relative;

                        .video-play-button{
                            z-index: 9;
                            width: 100px;
                            height: 100px;
                            text-align: center;
                            border: thin solid white;
                            border-radius: 50%;
                            display: grid;
                            align-content: center;
                            justify-content: center;
                            font-size: 110px;
                            padding-left: 10px;
                            position: absolute;
                            color: white;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) scale(1);
                            cursor: pointer;
                            transition: transform 0.1s ease-in-out;
                        }
                        .video-play-button:hover{
                            transform:translate(-50%, -50%) scale(1.07);
                        }
                    }

                    img{
                        width: 100%;
                        object-fit: cover;
                        height: 100%;
                    }
                }

                .collection-page-article-title{
                    font-size: 29px;
                    line-height: 31px;
                    font-family: "Suisse Medium";
                    padding-top: 10px;

                }

                .collection-page-article-byline{
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 17px;
                    font-family: "Suisse Regular";
                    padding-top: 5px;
                }
            }

            .collection-page-article:hover{
                .collection-page-article-image{
                    img{
                        mix-blend-mode: screen;
                        -webkit-filter: grayscale(100%) contrast(200%);
                        filter: grayscale(100%) contrast(200%);
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.no-scroll{
    position: fixed;
    overflow: hidden;
}


@media screen and (max-width:1100px){

    .collection-page-article-section{
        margin-top:20px !important;
    }

    .collection-page-article-row{
        margin-bottom:20px !important;
        padding: 0px 10px 0px 10px !important;
    }

    .collection-page-article-title{
        font-size: 28px !important;
        line-height: 30px !important;
        padding-top: 20px !important;
    }
}

@media screen and (max-width:540px){

    .collection-page-article-byline{
        font-size: 28px !important;
        line-height: 30px !important;
        padding-top: 0px !important;
    }
}
